import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { truncate } from "bundles/common/utils/utils";

const CompanyCard = ({ company, onClick, compact = false, handleSpontaneousApplyClick }) => {
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles(() => ({
    card: {
      borderRadius: "16px",
      cursor: "pointer",
      border: "1px solid lightgrey",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        ...(compact
          ? {
            transform: "translateY(-1px)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }
          : {
            boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
            border: `1px solid ${theme.palette.secondary.main}`,
          }),
      },
      overflow: "hidden",
      width: compact ? "275px" : "100%",
      boxShadow: compact ? "0 4px 8px rgba(0,0,0,0.3)" : "none",
      marginBottom: compact ? "16px" : "0",
    },
    header: {
      display: "flex",
      alignItems: "center",
      backgroundImage: isDesktop
        ? `url(${company.cover_picture_url})`
        : `url(${company.small_cover_picture_url})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      padding: "16px",
      height: compact ? "80px" : "115px",
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: "#FFF",
      padding: "16px",
      height: compact ? "170px" : "210px",
      boxSizing: "border-box",
    },
    logoImage: {
      width: compact ? "60px" : "80px",
      height: compact ? "60px" : "80px",
      objectFit: "contain",
    },
    nameRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "8px",
    },
    matchPercentage: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      fontWeight: "bold",
      fontSize: "0.775rem",
      padding: "1px 7px",
      borderRadius: "16px",
      display: "inline-block",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    jobCount: {
      color: theme.palette.primary.main,
      fontSize: compact ? "0.875rem" : "1rem",
    },
    infoIcon: {
      fontSize: compact ? "1rem" : "medium",
      marginRight: "3px",
      color: theme.palette.secondary.main,
    },
    infoText: {
      fontSize: compact ? "0.75rem" : "0.875rem",
      color: theme.palette.secondary.main,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "flex-end",
      height: "100%",
    },
    button: {
      position: "absolute",
      bottom: "0",
    },
    spontaneousApplyButton: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: "bolder",
      "&:hover $spontaneousApplyText": {
        textDecoration: "underline",
      },
    },
    spontaneousApplyText: {
      fontWeight: "bold",
      marginRight: "8px",
      fontSize: "0.775rem",
    },
  }));
  const classes = useStyles();

  // const isLandingPageRecruiter = window.location.pathname.includes("recruiters");
  // const isLandingPageCandidate =
  //   document.getElementById("landingPageCandidate") !== null;

  // const companyUrl = isLandingPageRecruiter
  //   ? `companies/${company.encoded_name}/${company.id}?redirectTo=recruiters`
  //   : isLandingPageCandidate
  //     ? `companies/${company.encoded_name}/${company.id}?redirectTo=candidates`
  //     : `companies/${company.encoded_name}/${company.id}?redirectTo=companiesIndex`;

  const { match_percentage: matchPercentage } = company;

  return (
    <Box className={classes.card} onClick={onClick}>
      <Box className={classes.header}>
        <img
          src={company.logo_url}
          srcSet={`${company.logo_url} 1x, ${company.small_logo_url} 0.5x`}
          alt={`Logo ${company?.name}`}
          loading="lazy"
          className={classes.logoImage}
        />
      </Box>
      <Box className={classes.footer}>
        <Box>
          <Box className={classes.nameRow}>
            <Typography
              variant="h5"
              style={{
                color: theme.palette.secondary.main,
                textTransform: "uppercase",
                marginRight: compact ? "15px" : "unset",
                fontSize: compact ? "0.95rem" : "1.25rem",
              }}>
              <span style={{ fontWeight: "600" }}>
                {truncate(company.name, 16)}
              </span>
            </Typography>
            {compact && matchPercentage !== undefined && (
              <Typography className={classes.matchPercentage}>
                {`${matchPercentage}% ${intl.formatMessage({
                  id: "candidate.match",
                })}`}
              </Typography>
            )}
          </Box>
          <Box style={{ marginBottom: "12px" }}>
            {(company.online_job_offers_count || 0) > 0 && (
              <Typography className={classes.jobCount}>
                <b>
                  {intl.formatMessage(
                    { id: "landing_candidate.jobs_count" },
                    { jobCount: company.online_job_offers_count }
                  )}
                </b>
              </Typography>
            )}
          </Box>
          {(company.city || company.region) && (
            <Box
              style={{
                display: "flex",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <LocationOnIcon className={classes.infoIcon} />
                <Typography className={classes.infoText}>
                  {company.city
                    ? company.city
                    : intl.formatMessage({ id: `regions.${company.region}` })}
                </Typography>
              </Box>
            </Box>
          )}
          {company.company_industry && (
            <Box
              style={{
                display: "flex",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <BusinessCenterIcon className={classes.infoIcon} />
                <Typography className={classes.infoText}>
                  <FormattedMessage
                    id={`company.company_industry.${company.company_industry}`}
                  />
                </Typography>
              </Box>
            </Box>
          )}
          {!compact && company.team_size && (
            <Box
              style={{
                display: "flex",
                color: "#000",
                marginBottom: "8px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <SupervisorAccountIcon className={classes.infoIcon} />
                <Typography className={classes.infoText}>
                  <FormattedMessage
                    id={`company.company_size.options.${company.team_size}`}
                  />{" "}
                  {intl
                    .formatMessage({ id: "company.employee_count" })
                    ?.toLowerCase()}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          {compact && (
            <Box
              mt={1}
              className={classes.spontaneousApplyButton}
              onClick={(e) => {
                e.stopPropagation();
                handleSpontaneousApplyClick(company.id);
              }}
            >
              <Typography
                className={classes.spontaneousApplyText}
                component="span"
              >
                <FormattedMessage id="candidate.apply_button" />
              </Typography>
              <ArrowCircleRightOutlinedIcon />
            </Box>
          )}
          {/* // <Button
          //   href={companyUrl}
          //   variant={"textUnderlineOnHover"}
          //   onClick={
          //     isLandingPageRecruiter || isLandingPageCandidate
          //       ? undefined
          //       : onClick
          //   }
          //   className={classes.button}
          // >
          //   <FormattedMessage id="company_card.see_company" />
          // </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyCard;
