import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const DemoBanner = ({isVisible}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles(() => ({
    banner: {
      width: isDesktop ? "1121px" : "100%",
      height: isDesktop ? "160px" : "100%",
      padding: theme.spacing(4),
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      opacity: 0,
      backgroundImage: "linear-gradient(180deg, #2A334E 0%, #465275 100%)",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      overflow: "hidden",
      transition: "transform .2s ease-in-out",

      "&:hover": {
        transform: "scale(1.08)",
      },

      "&::before": {
        content: "\"\"",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transition: "box-shadow 0.3s",
        boxShadow: "inset 0 0 0 8px transparent",
      },

      "&:hover::before": {
        boxShadow: "inset 0 0 0 8px #079344",
        borderRadius: "20px"
      },
    },
    fadeIn: {
      animation: "$fadeIn 0.5s ease-in-out forwards",
    },
    fadeOut: {
      animation: "$fadeOut 0.5s ease-in-out forwards",
    },
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#079344 !important",
      },
      "&:hover $textContainer": {
        marginRight: "0px",
      },
      "&:hover $arrowIcon": {
        opacity: 1,
      },
    },
    textContainer: {
      transition: "margin-right 0.3s",
      marginRight: "-32px",
    },
    arrowIcon: {
      opacity: 0,
      marginLeft: "8px",
      transition: "opacity 0.3s",
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
    "@keyframes fadeOut": {
      "0%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    },
  }));
  const classes = useStyles();
  const bannerAnimation = isVisible ? classes.fadeIn : classes.fadeOut;

  const linkUrl = "https://meetings-eu1.hubspot.com/meetings/vincent-babet";

  return (
    <>
      <Container>
        <a href={linkUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", display: "block" }}>
          <Box className={`${classes.banner} ${bannerAnimation}`}>
            <Grid container style={{ display: "flex", justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                md={9}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Work Sans Bold",
                    fontSize: isDesktop ? "36px" : "24px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  <FormattedMessage id="landing_recruiter.banner.message" />
                </Typography>
                <Box>
                  <Typography
                    variant="h6"
                    style={{ color: "white", paddingTop: "10px" }}
                  >
                    <FormattedMessage id="landing_recruiter.banner.sub_message" />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  display={isDesktop ? "block" : "flex"}
                  justifyContent="center"
                >
                  <Button
                    className={classes.button}
                    variant="rounded"
                    color="primaryContained"
                    size={isDesktop ? "medium" : "small"}
                    style={{ marginTop: "16px", width: "250px", fontWeight: "bold" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://meetings-eu1.hubspot.com/meetings/vincent-babet"
                  >
                    <Box className={classes.textContainer}>
                      <FormattedMessage
                        id="landing_recruiter.banner.button"
                      />
                    </Box>
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </a>
      </Container>
    </>
  );
};

export default DemoBanner;
