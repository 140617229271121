import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Office from "icons/office_icon.svg";
import Bag from "icons/working_bag_icon.svg";
import Person from "icons/person_looking_icon.svg";
import JobOfferList from "bundles/Candidate/components/JobOfferList";
import AuthenticationModal from "bundles/Candidate/components/AuthenticationModal";
import sh from "bundles/common/utils/sh";
// import LandingBot from "./LandingBot";
import LandingReviews from "./LandingReviews";
import CompanyCard from "bundles/Recruiter/components/CompanyCard";
import BlogPostCard from "bundles/common/components/BlogPostCard";
import NewContactForm from "bundles/common/components/NewContactForm";
import Carousel from "react-material-ui-carousel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ButtonBase from "@material-ui/core/ButtonBase";

const MobileLandingPage = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const intl = useIntl();
  const useStyles = makeStyles(() => ({
    generateCvBox: {
      width: "80%",
      height: "auto",
      backgroundImage: "radial-gradient(circle, #3F4B71, #2A334E)",
      position: "relative",
      padding: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px",
      border: "8px solid transparent",
      "&:hover": {
        border: "8px solid #079344",
        boxSizing: "border-box",
      },
      "&:hover $cvImageStyle": {
        transform: "scale(1.08)",
      },
    },
    cvImage: {
      position: "absolute",
      top: "-10%",
      bottom: "-10%",
      right: "8%",
      width: "auto",
      height: "126%",
      boxShadow:
        "0px 4px 4px rgba(128, 128, 128, 1), 8px 8px 4px rgba(192, 192, 192, 1)",
      transition: "transform .2s ease-in-out",
      "&:hover": {
        transform: "scale(1.28)",
      },
    },
    contactForm: {
      backgroundImage:
        "radial-gradient(ellipse at 50% 50%, #3F4C71 0.5%, #3F4C71 15%, #2A334E 50%, #2A334E 100%)",
      dropShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      padding: "32px 20px",
    },
    ellipseStyle: {
      borderRadius: "50%",
      width: "140px",
      height: "140px",
      objectFit: "cover",
      border: "2px solid white",
    },
    cvWrapper: {
      position: "relative",
      width: "100%",
      height: "400px",
    },
    cvImageStyle: {
      position: "absolute",
      top: "-10%",
      bottom: "-10%",
      right: "8%",
      width: "auto",
      height: "126%",
      border: "0.4px solid lightgrey",
      transition: "transform .2s ease-in-out",
    },
    zoom_icon: {
      position: "absolute",
      top: "6%",
      right: "10%",
      fontSize: "1.4rem",
      color: "grey",
      opacity: 1,
      marginTop: "22px",
    },
    customCvButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#079344 !important",
      },
      "&:hover $textContainer": {
        marginRight: "0px",
      },
      "&:hover $arrowIcon": {
        opacity: 1,
      },
    },
    textContainer: {
      transition: "margin-right 0.3s",
      marginRight: "-32px",
    },
    arrowIcon: {
      opacity: 0,
      marginLeft: "8px",
      transition: "opacity 0.3s",
    },
  }));

  const classes = useStyles();

  const [jobOffers, setJobOffers] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const [loginToAddResume, setLoginToAddResume] = useState(false);
  const [loginToAddFavorite, setLoginToAddFavorite] = useState(false);
  const [singleJob, setSingleJob] = useState(true);
  const [companyCount, setCompanyCount] = useState(0);
  const [jobOfferYearCount, setJobOfferYearCount] = useState(0);
  const [jobInterviewYearCount, setJobInterviewYearCount] = useState(0);
  const [trustedCompanies, setTrustedCompanies] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [squarehubId, setSquarehubId] = useState(null);

  const trustedCompanyIds = JSON.parse(process.env.TRUSTED_COMPANY_IDS) || [];
  const mobileWidgetId = process.env.REACT_APP_ELFSIGHT_WIDGET_MOBILE || null;

  const fetchDataCount = () => {
    sh.get("landing_candidate_data", { authenticity_token: authenticityToken })
      .then((res) => {
        setCompanyCount(res.data.companies_count);
        setJobOfferYearCount(res.data.job_offer_year_count);
        setJobInterviewYearCount(res.data.job_interview_year_count);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchEightJobs = () => {
    setLoading(true);
    sh.get("random_jobs", {
      params: {
        quantity: 8,
        authenticity_token: authenticityToken,
      },
    })
      .then((res) => {
        setJobCount(res.data?.job_count);
        setJobOffers(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTrustedCompanies = () => {
    sh.get("search/companies", {
      params: {
        authenticity_token: authenticityToken,
        filters: { id: trustedCompanyIds },
      },
    })
      .then((res) => {
        const companies = res.data.data.companies.slice(0, 4);
        setTrustedCompanies(companies);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchFourBlogPosts = () => {
    sh.get("search/blog_posts", {
      params: {
        authenticity_token: authenticityToken,
        filters: { resource_type: "candidate" },
      },
    })
      .then((res) => {
        const posts = res.data.data.blog_posts.slice(0, 4);
        setBlogPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOfferClicked = (offer, e, addFavorite = false) => {
    e.preventDefault();
    if (currentCandidate) {
      window.location.href = `/${i18nLocale}/candidate/job_offers?jid=${offer.id}`;
    } else {
      setSingleJob(true);
      setCurrentTarget(`/${i18nLocale}/candidate/job_offers?jid=${offer.id}`);
      setLoginToAddFavorite(addFavorite);
      setIsLoginModalOpened(true);
    }
  };

  const handleAllOffersClicked = (e) => {
    e.preventDefault();
    if (currentCandidate) {
      window.location.href = `/${i18nLocale}/candidate/job_offers`;
    } else {
      setSingleJob(false);
      setCurrentTarget(`/${i18nLocale}/candidate/job_offers`);
      setIsLoginModalOpened(true);
    }
  };

  const handleAddResumeClicked = (e) => {
    e.preventDefault();
    if (currentCandidate) {
      window.location.href = `/${i18nLocale}/candidate/settings`;
    } else {
      setLoginToAddResume(true);
      setCurrentTarget(`/${i18nLocale}/candidate/settings`);
      setIsLoginModalOpened(true);
    }
  };

  const handleCreateResumeClicked = (e) => {
    e.preventDefault();
    if (currentCandidate) {
      window.location.href = `/${i18nLocale}/candidate/settings?selectedSection=resume_generator`;
    } else {
      setLoginToAddResume(true);
      setCurrentTarget(
        `/${i18nLocale}/candidate/settings?selectedSection=resume_generator`
      );
      setIsLoginModalOpened(true);
    }
  };

  const onAuthenticationModalClosed = () => {
    setIsLoginModalOpened(false);
    setTimeout(() => {
      setLoginToAddResume(false);
      setLoginToAddFavorite(false);
    }, 200);
  };

  useEffect(() => {
    sh.get(`/${i18nLocale}/find_squarehub_id`)
      .then((response) => {
        if (response?.data?.id) {
          setSquarehubId(response.data.id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18nLocale]);

  useEffect(() => {
    if (currentCandidate?.id && currentTarget) {
      setCurrentTarget(null);
      let showOnboarding = currentCandidate.new_sign_in_count <= 1;
      let redirectParams = singleJob
        ? `&showOnboarding=${showOnboarding}`
        : `?showOnboarding=${showOnboarding}`;
      window.location.href = `${currentTarget}${redirectParams}`;
    }
  }, [currentCandidate?.id]);

  useEffect(() => {
    fetchDataCount();
    fetchEightJobs();
    fetchTrustedCompanies();
    fetchFourBlogPosts();
  }, []);


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* Landing Banner  */}
      <Box className={classes.container} id="landingPageCandidate">
        <Container>
          <Grid container spacing={2}>
            {/* Mobile presentation */}
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h2"
                color="secondary"
                style={{ marginTop: "16px", fontSize: "24px" }}
              >
                <FormattedMessage
                  id="landing_candidate.welcome_html"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                style={{
                  color: theme.palette.common.blue,
                  fontSize: "32px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "24px",
                }}
              >
                <FormattedMessage
                  id="landing_candidate.baseline_html"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>  {/* Adjusted to take half the width */}
              <Button
                fullWidth
                href={`/${i18nLocale}/candidates/sign_in`}
                variant="rounded"
                color="primaryContained"
              >
                <FormattedMessage id="signin" />
              </Button>
            </Grid>
            <Grid item xs={6}>  {/* Adjusted to take half the width */}
              <Button
                fullWidth
                href={`/${i18nLocale}/candidates/sign_up`}
                variant="rounded"
                color="primaryOutlined"
              >
                <FormattedMessage id="signup" />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                href={`/${i18nLocale}/recruiters`}
                variant="text"
                size="small"
              >
                <FormattedMessage id="footer.go_to_recruiter" />
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Box
                style={{
                  height: "60vh",
                }}
              >
                <LandingBot />
              </Box>
            </Grid> */}
          </Grid>
          <Box
            className={`elfsight-app-${mobileWidgetId}`}
            data-elfsight-app-lazy
            data-lang={i18nLocale}
            sx={{
              marginTop: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          />
          {/* Anonymized jobs */}
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Box style={{ marginTop: "32px" }}>
                  <Typography
                    variant="h2"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "34px",
                    }}
                    color="secondary"
                  >
                    <FormattedMessage id="landing_candidate.our_job_offers" />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Box align="center">
                    <CircularProgress size={24} color="secondary">
                      <FormattedMessage id="loading" />
                    </CircularProgress>
                  </Box>
                ) : (
                  <JobOfferList
                    offers={jobOffers}
                    handleOfferClick={handleOfferClicked}
                    columnPerRow={1}
                    redirect={!currentCandidate}
                    logoHoverAndRedirect={true}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ marginBottom: "32px" }}
                textAlign="center"
              >
                <Button
                  variant="rounded"
                  color="primaryContained"
                  style={{ minWidth: "200px" }}
                  onClick={handleAllOffersClicked}
                >
                  {jobCount < 100 ? (
                    <FormattedMessage id="landing_candidate.less_than_100_jobs" />
                  ) : (
                    <FormattedMessage
                      id="landing_candidate.see_more_jobs"
                      values={{ jobCount: jobCount }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* How to find a Job on Squarehub */}
      <Grid
        container
        justifyContent="center"
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} style={{ padding: "0 24px" }}>
          <Typography
            variant="h2"
            style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
              fontWeight: "bold",
              fontSize: "34px",
            }}
            color="secondary"
          >
            <FormattedMessage id="landing_candidate.how_to_find_a_job" />
          </Typography>
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              fontSize: "18px",
              maxWidth: "100%",
              width: "400px",
              margin: "30px auto",
            }}
            color="secondary"
          >
            <FormattedMessage id="landing_candidate.augment_your_chances" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              padding: "16px 40px",
              display: "flex",
              flexDirection: "column",
              rowGap: "40px",
            }}
          >
            <Box
              style={{
                flex: "1 1 150px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                rowGap: "20px",
                height: "300px",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "20px",
                }}
              >
                <img
                  src={Office}
                  alt={intl.formatMessage({
                    id: "landing_recruiter.recruting.alt_1",
                  })}
                  width="40px"
                />
                <Typography
                  variant="h5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                    width: "260px",
                  }}
                  color="secondary"
                >
                  <FormattedMessage
                    id="landing_candidate.apply_to_jobs"
                    values={{ companyCount }}
                  />
                </Typography>
                <Typography
                  variant="h7"
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  color="secondary"
                >
                  <FormattedMessage id="landing_candidate.apply_to_jobs_text" />
                </Typography>
              </Box>
              <Box>
                <Button
                  color="primaryOutlined"
                  variant="rounded"
                  size="medium"
                  style={{
                    margin: "auto",
                    width: "250px",
                  }}
                  onClick={handleAllOffersClicked}
                >
                  <FormattedMessage id="landing_candidate.see_jobs" />
                </Button>
              </Box>
            </Box>
            <Box
              style={{
                flex: "1 1 150px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "20px",
                height: "300px",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "20px",
                }}
              >
                <img
                  src={Bag}
                  alt={intl.formatMessage({
                    id: "landing_recruiter.recruting.alt_2",
                  })}
                  width="40px"
                />
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                    width: "260px",
                  }}
                  color="secondary"
                >
                  <FormattedMessage id="landing_candidate.apply_sponteanously" />{" "}
                </Typography>
                <Typography
                  variant="h7"
                  style={{
                    marginBottom: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  color="secondary"
                >
                  <FormattedMessage id="landing_candidate.apply_sponteanously_text" />
                </Typography>
              </Box>
              <Box>
                <Button
                  color="primaryOutlined"
                  variant="rounded"
                  size="medium"
                  href={`/${i18nLocale}/companies`}
                  style={{
                    margin: "auto",
                    width: "250px",
                  }}
                >
                  <FormattedMessage id="landing_candidate.see_recruiters" />
                </Button>
              </Box>
            </Box>
            <Box
              style={{
                flex: "1 1 150px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                rowGap: "20px",
                height: "300px",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "20px",
                }}
              >
                <img
                  src={Person}
                  alt={intl.formatMessage({
                    id: "landing_recruiter.recruting.alt_3",
                  })}
                  width="40px"
                  height="38px"
                />
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                    width: "260px",
                  }}
                  color="secondary"
                >
                  <FormattedMessage id="landing_candidate.get_noticed" />
                </Typography>
                <Typography
                  variant="h7"
                  style={{
                    marginBottom: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  color="secondary"
                >
                  <FormattedMessage id="landing_candidate.get_noticed_text" />
                </Typography>
              </Box>
              <Box>
                <Button
                  color="primaryOutlined"
                  variant="rounded"
                  size="medium"
                  onClick={handleAddResumeClicked}
                  style={{
                    width: "250px",
                    margin: "auto",
                  }}
                >
                  <FormattedMessage id="landing_candidate.add_cv" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* {Generate CV} */}
      <Grid
        container
        justify="center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          padding: "60px 0px 40px",
        }}
      >
        <ButtonBase style={{ width: "100%" }} onClick={handleCreateResumeClicked}>
          <Box
            className={classes.generateCvBox}
            style={{ marginTop: "10px" }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ padding: "20px 20px" }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: "white",
                    marginBottom: "22px",
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  <FormattedMessage id="landing_candidate.generate_cv.title" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "white", marginBottom: "16px", textAlign: "left" }}
                >
                  <FormattedMessage id="landing_candidate.generate_cv.description" />
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={{ marginBottom: "16px" }}
                >
                  <ListItem style={{ padding: "0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      ✓{" "}
                      <FormattedMessage id="landing_candidate.generate_cv.point_1" />
                    </Typography>
                  </ListItem>
                  <ListItem style={{ padding: "0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      ✓{" "}
                      <FormattedMessage id="landing_candidate.generate_cv.point_2" />
                    </Typography>
                  </ListItem>
                  <ListItem style={{ padding: "0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      ✓{" "}
                      <FormattedMessage id="landing_candidate.generate_cv.point_3" />
                    </Typography>
                  </ListItem>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="rounded"
                    color="primaryContained"
                    size="small"
                    className={classes.customCvButton}
                    style={{ marginTop: "16px", width: "250px" }}
                    onClick={handleCreateResumeClicked}
                  >
                    <Box className={classes.textContainer}>
                      <FormattedMessage id="landing_candidate.generate_cv.call_to_action" />
                    </Box>
                    <ArrowForwardIcon className={classes.arrowIcon} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </ButtonBase>
      </Grid>

      {/* {Companies that trust us} */}
      <Grid
        container
        style={{ backgroundColor: "white", padding: "0px 20px 40px" }}
      >
        <Box>
          <Typography
            variant="h2"
            style={{
              color: theme.palette.secondary.main,
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
              fontSize: "34px",
              textAlign: "center",
            }}
          >
            <b>
              <FormattedMessage
                id="landing_candidate.companies_trust_us"
                values={{ companyCount: companyCount }}
              />
            </b>
          </Typography>
        </Box>
        <Box
          mx="auto"
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: theme.palette.secondary.main,
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            <FormattedMessage
              id="landing_candidate.companies_description"
              values={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Typography>
        </Box>
        <Grid item xs={10} style={{ margin: "0 auto" }}>
          <Carousel autoPlay={false} animation="slide">
            {trustedCompanies.map((company, index) => {
              return (
                <CompanyCard
                  key={`company-${index}`}
                  company={company}
                  onClick={() => {
                    window.location.href = `${window.location.origin}/${i18nLocale}/companies/${company?.encoded_name}/${company?.id}`;
                  }}
                />
              );
            })}
          </Carousel>
        </Grid>
      </Grid>

      {/* Career Advises */}
      <Grid container style={{ padding: "40px 24px" }}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" marginBottom="40px">
            <Typography
              variant="h2"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "34px",
              }}
              color="secondary"
            >
              <FormattedMessage id="landing_candidate.career_advice_title" />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            {blogPosts
              .slice(0, 2)
              .map((post, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    p={2}
                  >
                    <BlogPostCard
                      post={post}
                      companyId={squarehubId}
                      landing={true}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" marginTop="48px">
            <Button
              variant="rounded"
              color="primaryContained"
              style={{ minWidth: "300px" }}
              href={`/${i18nLocale}/candidates/posts`}
            >
              <FormattedMessage id="landing_candidate.view_our_advises" />
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Reviews Carousel */}
      <Grid
        container
        style={{ backgroundColor: "white", padding: "20px 40px" }}
      >
        <LandingReviews />
      </Grid>

      {/* Contact Form */}
      <Grid className={classes.contactForm}>
        <Container maxWidth="lg">
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid
              item
              xs={12}
              sm={7}
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "40px",
                paddingBottom: "35px",
              }}
            >
              <Box
                style={{
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: "white",
                    fontWeight: "600",
                    fontSize: "32px",
                  }}
                >
                  <FormattedMessage
                    id="landing_candidate.contact"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              </Box>
              <Box
                style={{
                  maxWidth: "600px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    color: "white",
                    fontSize: "18px",
                    paddingRight: "0px",
                  }}
                >
                  <FormattedMessage
                    id="landing_candidate.contact_sub1"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: "white",
                    fontSize: "18px",
                    paddingRight: "0px",
                    marginTop: "32px",
                  }}
                >
                  <FormattedMessage
                    id="landing_candidate.contact_sub2"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box
                style={{
                  width: "100%",
                }}
              >
                <NewContactForm candidateForm={true} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <AuthenticationModal
        isOpen={isLoginModalOpened}
        section="login_or_signin"
        closeModal={onAuthenticationModalClosed}
        singleJob={singleJob}
        addResumeLogin={loginToAddResume}
        addFavoriteLogin={loginToAddFavorite}
      />
    </>
  );
};

export default MobileLandingPage;
