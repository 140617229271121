import React, { useState } from "react";
import PricingModal from "bundles/LandingPageRecruiter/components/PricingModal";
import {
  Grid,
  Typography,
  Box,
  Paper,
  useMediaQuery,
  Modal,
  Button,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import styled from "styled-components";

const PricingDetails = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles(() => ({
    container: {
      width: "100% !important",
      marginLeft: "-20px !important",
    },
  }));
  const classes = useStyles();
  const settings = {
    arrows: false,
    dots: true,
    className: "slick-list",
    infinite: false,
    adaptiveHeight: true,
    initialSlide: 1,
  };

  const prices = [
    {
      name: "Starter",
      title: "landing_recruiter.prices.starter2025.title",
      number_annonce: "landing_recruiter.prices.starter2025.number_annonce",
      bulletpoints: "landing_recruiter.prices.starter2025.bulletpoints",
      annonce: "landing_recruiter.prices.starter2025.annonce",
      nickname: "starter2025",
    },
    {
      name: "Business",
      title: "landing_recruiter.prices.business2025.title",
      number_annonce: "landing_recruiter.prices.business2025.number_annonce",
      bulletpoints: "landing_recruiter.prices.business2025.bulletpoints",
      annonce: "landing_recruiter.prices.business2025.annonce",
      offerBulletpoints:
        "landing_recruiter.prices.business2025.offer_bulletpoints",
      nickname: "business2025",
      popular: true,
    },
    {
      name: "Pro",
      title: "landing_recruiter.prices.pro2025.title",
      number_annonce: "landing_recruiter.prices.pro2025.number_annonce",
      bulletpoints: "landing_recruiter.prices.pro2025.bulletpoints",
      annonce: "landing_recruiter.prices.pro2025.annonce",
      offerBulletpoints:
        "landing_recruiter.prices.pro2025.offer_bulletpoints",
      nickname: "pro2025",
      grey_banner: true,
    },
  ];

  const StyledSlider = styled(Slider)`
    & .slick-list {
      height: inherit;

      & .slick-track {
        display: flex !important;
        height: 100%;

        & .slick-slide {
          height: 490px;
        }
      }
    }
  `;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Grid
        container
        spacing={isDesktop ? 12 : 4}
        display={isDesktop ? "flex" : "block"}
        justifyContent="center"
        className={classes.container}
        style={{ marginBottom: "12px", marginTop: "3px" }}
      >
        {isDesktop ? (
          prices.map((price, index) => (
            <Grid
              item
              md={4}
              xs={12}
              key={price.title + index}
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                marginTop: index == 1 ? "-48px" : "0px",
              }}
            >
              {price.popular && (
                <Box position="relative" mt={0}>
                  <Box
                    top="-35px"
                    color="white"
                    width="378px"
                    height="48px"
                    bgcolor={theme.palette.primary.main}
                    pt={1}
                    style={{
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ marginTop: "4px", fontWeight: "bold" }}
                    >
                      <b>
                        <FormattedMessage id="landing_recruiter.prices.popular" />
                      </b>
                    </Typography>
                  </Box>
                </Box>
              )}
              {price.grey_banner && (
                <Box position="relative" mt={0}>
                  <Box
                    position="absolute"
                    top="-28px"
                    left="30"
                    width="377px"
                    py={0.5}
                    bgcolor={theme.palette.grey[400]}
                    color={theme.palette.common.white}
                    style={{
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                    }}
                  >
                    <Typography
                      align="center"
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                    >
                      <FormattedMessage id="landing_recruiter.grey_banner" />
                    </Typography>
                  </Box>
                </Box>
              )}
              <Paper
                component={Box}
                p={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "440px",
                  width: "378px",
                  padding: "0px",
                  marginLeft: index == 0 ? "auto" : "0px",
                  marginRight: index == 0 ? "0px" : "auto",
                  borderTopLeftRadius: price.popular ? "0px" : "4px",
                  borderTopRightRadius: price.popular ? "0px" : "4px",
                }}
              >
                <Box py={1}>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ paddingTop: "24px", paddingBottom: "10px", fontFamily: "Work Sans Bold" }}
                  >
                    <b>
                      <FormattedMessage id={price.title} />
                    </b>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "left",
                    flexDirection: "column",
                    height: "100%",
                    padding: "5px",
                  }}
                >
                  <Box py={1}>
                    <FormattedMessage
                      id={price.bulletpoints}
                      values={{
                        li: (chunks) => (
                          <li
                            key={chunks}
                            style={{
                              listStyle: "none",
                              display: "flex",
                              marginBottom: "8px",
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: "5px",
                                marginLeft: "8px",
                                color: theme.palette.primary.main,
                              }}
                            >
                              ✓
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ textAlign: "left" }}
                            >
                              {chunks}
                            </Typography>
                          </li>
                        ),
                      }}
                    />
                    <Box style={{ marginTop: "32px" }}>
                      {price.offerBulletpoints && (
                        <FormattedMessage
                          id={price.offerBulletpoints}
                          values={{
                            li: (chunks) => (
                              <li
                                key={chunks}
                                style={{
                                  listStyle: "none",
                                  display: "flex",
                                  alignItems: "baseline",
                                  marginBottom: "8px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginRight: "5px",
                                    marginLeft: "8px",
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  ✓
                                </Typography>
                                <Typography variant="body2">
                                  {chunks}
                                  <b
                                    style={{
                                      marginLeft: "8px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    <FormattedMessage id="landing_recruiter.prices.offer" />
                                  </b>
                                </Typography>
                              </li>
                            ),
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      width="100%"
                      onClick={() => {
                        window.location.href =
                          "mailto:jules@squarehub.eu?subject=Order License";
                      }}
                    >
                      <FormattedMessage
                        id="order.order"
                      />
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))
        ) : (
          <StyledSlider {...settings}>
            {prices.map((price, index) => (
              <Grid
                key={`carousel-mobile-${index}`}
                item
                xs={12}
              >
                <Paper
                  component={Box}
                  p={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    overflow: "hidden",
                    marginLeft: "45px",
                    height: "480px",
                  }}
                >
                  {price.popular && (
                    <Box position="relative" style={{ height: "5px" }}>
                      <Box
                        position="absolute"
                        top="-40px"
                        color="white"
                        bgcolor={theme.palette.primary.main}
                        pt={1}
                        style={{
                          transform: "rotate(45deg)",
                          top: "-1px",
                          right: "-66px",
                          width: "54%",
                          height: "auto",
                        }}
                      >
                        <Typography
                          align="center"
                          variant="h5"
                          style={{
                            paddingBottom: "1px",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                          }}
                        >
                          <b>
                            <FormattedMessage id="landing_recruiter.prices.popular" />
                          </b>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box py={1} height="auto" component="span">
                    <Typography
                      variant="h2"
                      align="center"
                      style={{ fontWeight: "bolder", fontSize: "22px" }}>
                      <b>
                        <FormattedMessage id={price.title} />
                      </b>
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                      padding: "16px",
                    }}
                  >
                    <Box py={1}>
                      <FormattedMessage
                        id={price.bulletpoints}
                        values={{
                          li: (chunks) => (
                            <li
                              key={chunks}
                              style={{
                                listStyle: "none",
                                display: "flex",
                                alignItems: "baseline",
                                marginBottom: "4px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  marginRight: "8px",
                                  color: theme.palette.primary.main,
                                }}
                              >
                                ✓
                              </Typography>
                              <Typography variant="body2">{chunks}</Typography>
                            </li>
                          ),
                        }}
                      />
                      <Box style={{ marginTop: "35px" }}>
                        {price.offerBulletpoints && (
                          <FormattedMessage
                            id={price.offerBulletpoints}
                            values={{
                              li: (chunks) => (
                                <li
                                  key={chunks}
                                  style={{
                                    listStyle: "none",
                                    display: "flex",
                                    alignItems: "baseline",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      marginRight: "8px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    ✓
                                  </Typography>
                                  <Typography variant="body2">
                                    {chunks}
                                    <b
                                      style={{
                                        marginLeft: "8px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      <FormattedMessage id="landing_recruiter.prices.offer" />
                                    </b>
                                  </Typography>
                                </li>
                              ),
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: "22px" }}
                      onClick={() =>
                        window.location.href =
                          "mailto:jules@squarehub.eu?subject=Order License"
                      }
                    >
                      <FormattedMessage
                        id="order.order"
                      />
                    </Button>
                    {price.grey_banner && (
                      <Typography variant="h4" color="secondaryMain" style={{ marginTop: "17px" }}>
                        <FormattedMessage id="landing_recruiter.grey_banner"/>
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </StyledSlider>
        )}
        <Box
          textAlign="center"
          width="100%"
          style={{ marginTop: "32px", marginLeft: "16px", marginBottom: "32px" }}>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage
              id="landing_recruiter.vat_excluded"
            />
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        spacing={isDesktop ? 2 : 4}
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      ></Grid>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PricingModal closeModal={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export default PricingDetails;
